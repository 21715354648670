<div class="main" fxLayout="row wrap">
    <div fxFlex></div>

    <!-- USER -->
    <div *ngIf="user" class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">Ž</mat-icon>
            <div fxLayout="column">
                <div fxLayout="row wrap" fxFlex>
                    <h2 *ngIf="id !== 'new'" fxFlex="200px" fxLayoutAlign="start center">{{ 'maintain_users.topic' | translate }}</h2>
                    <h2 *ngIf="id === 'new'" fxFlex="200px" fxLayoutAlign="start center">{{ 'maintain_users.add_new_user' | translate }}</h2>
                    <div fxFlex></div>
                    <div *ngIf="user" fxLayout="column">
                        <h2 fxLayout="row" fxLayoutGap="20px">
                            <div>{{ user.data.name }}</div>
                            <i *ngIf="!user.active">*{{ 'common.inactive' | translate }}</i>
                        </h2>
                    </div>
                    <div fxFlex></div>
                </div>
            </div>
        </div>
    
        <div class="full container" [ngClass]="{'NOT-ACTIVE': !user.active}">

            <ng-container *ngIf="isKoyAdmin || isSubAdmin">
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <div fxFlex></div>
                    <div class="id-field">ID: {{ user.id }}</div>
                    <div class="id-field">Auth0: {{ user.data.userId }}</div>
                </div>
            </ng-container>

            <!-- GENERAL -->
            <h2 class="mat-subhead">{{ 'common.general' | translate }}</h2>
            <div fxLayout="column">
                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <div fxLayout="row">
                        <mat-checkbox [(ngModel)]="user.active" [disabled]="id === 'new'">{{ 'common.active' | translate }}</mat-checkbox>
                    </div>
                    <ng-container *ngIf="isKoyAdmin || isSubAdmin">
                        <div fxLayout="row">
                            <mat-checkbox [(ngModel)]="user.data.internal" [disabled]="!isSysAdmin">{{ 'common.internal' | translate }} <small>(Kemppi SSO)</small></mat-checkbox>
                            <mat-checkbox *ngIf="!user.data.userId" [(ngModel)]="user.data.initial" [disabled]="!isSysAdmin">{{ 'common.initial' | translate }} <small>(User must self register)</small></mat-checkbox>
                        </div>
                        <div fxLayout="row">
                            <small *ngIf="id === 'new' && !user.data.internal && !user.data.initial" class="weak"><i>Welcome email with verification link to set up a password will be sent to user after this form is saved.</i></small>
                            <small *ngIf="id === 'new' && user.data.initial" class="weak"><i>(Not Auth0 user yet, allow self registration afterwards)</i></small>
                        </div>
                    </ng-container>
                </div>
                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field *ngIf="user.data.selfRegistered">
                        <mat-label>{{ 'maintain_users.registration_status' | translate }}</mat-label>
                        <mat-select [(value)]="user.data.approval" required>
                            <mat-option [value]="regApprovalStates.undeterminated">{{ 'common.undetermined' | translate}}</mat-option>
                            <mat-option [value]="regApprovalStates.approved">{{ 'common.approved' | translate}}</mat-option>
                            <mat-option [value]="regApprovalStates.declined">{{ 'common.declined' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="isKoyAdmin || isSubAdmin">
                        <mat-form-field [style.width]="'300px'">
                            <mat-label>{{ 'common.notes' | translate }}</mat-label>
                            <input matInput [(ngModel)]="user.data.notes">
                            <mat-hint *ngIf="user.data.approval === regApprovalStates.declined" class="warn">{{ 'common.decline_reason' | translate }}?</mat-hint>
                        </mat-form-field>
                    </ng-container>
                </div>

            </div>
            
            <!-- PARTNER -->
            <h2 class="mat-subhead">{{ 'common.partner' | translate }}</h2>
            <div fxLayout="column">
                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <form>
                        <mat-form-field fxFlex="180px" [attr.readonly]="isCustomerSuperUser">
                            <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                            <input type="text" matInput [formControl]="customersFormControl" [matAutocomplete]="auto" [(ngModel)]="searchPartner" (keyup)="searchCustomers()" [readonly]="isCustomerSuperUser">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeCustomer($event.option)">
                                <mat-option *ngFor="let customer of customersList" [value]="customer">
                                    <small fxFlex>{{ customer.name }}</small>
                                    <small fxFlex="10px"></small>
                                    <small fxFlex="40px"><b>{{ customer.company }}</b></small>
                                    <small fxFlex="80px"><b>{{ customer.custId }}</b></small>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-hint *ngIf="isSysAdmin && user.data.groupCode">{{ user.data.groupCode }} - {{ user.data.groupDesc }}</mat-hint>
                        </mat-form-field>
                    </form>
                    <ng-container *ngIf="isKoyAdmin || isSubAdmin">
                        <mat-form-field>
                            <mat-label>{{ 'common.company' | translate }}</mat-label>
                            <mat-select [(value)]="user.company" required (selectionChange)="updatePartnerName()">
                                <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} - {{ company.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                            <input matInput [(ngModel)]="user.partnerId" (change)="updatePartnerName()" required>
                        </mat-form-field>
                        <button mat-button (click)="updatePartnerName()" class="smaller-button" [disabled]="state.loading">
                            <mat-icon>refresh</mat-icon>
                            {{ 'common.update' | translate }}
                        </button>
                    </ng-container>
                </div>
            </div>

            <!-- USER -->
            <h2 class="mat-subhead">{{ 'common.user' | translate }}</h2>
            <div fxLayout="column">
                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>{{ 'common.name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="user.data.name" required>
                    </mat-form-field>
                    <mat-form-field [style.width]="'300px'">
                        <mat-label>{{ 'common.email' | translate }}</mat-label>
                        <input matInput [(ngModel)]="user.data.email" (change)="checkUserEmail()" [disabled]="user.data.email_verified" required>
                        <mat-hint *ngIf="user.data.email_verified">{{ 'common.verified' | translate }}</mat-hint>
                    </mat-form-field>
                </div>
                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>{{ 'common.language' | translate }}</mat-label>
                        <mat-select [(value)]="user.data.language" required>
                            <mat-option *ngFor="let lang of availableLanguages" [value]="lang">{{ lang }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'common.country' | translate }}</mat-label>
                        <mat-select [(value)]="user.data.country" required>
                            <mat-option *ngFor="let country of countryList" [value]="country">{{ country }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutGap="30px">
                <!-- APPLICATIONS & ROLES -->
                <div fxFlex="600px" fxLayout="column" fxLayoutGap="10px">

                    <ng-container *ngIf="isKoyAdmin || isSubAdmin">
                        <!-- KOY / SUBSIDIARY EDIT -->
                        <h2 class="mat-subhead">{{ 'common.applications' | translate }} & {{ 'common.roles' | translate }}</h2>
                        <ng-container *ngFor="let appId of applicationIds">
                            <!-- ROLES -->
                            <ng-container *ngIf="applications[appId] && applications[appId].roles && !applications[appId].publicAccess">
                                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                    <h4 fxFlex="150px" class="application-name" [ngClass]="{'disabled-app': applications[appId].disabled || (!user.data.internal && applications[appId].internalOnly)}">{{ applications[appId].name }}</h4>
                                    <mat-form-field fxFlex="150px">
                                        <!-- (selectionChange)="setAppRole(appId, attribute.key, $event)" -->
                                        <mat-select [(value)]="user.data.roles[appId]" [disabled]="(applications[appId].disabled || (!user.data.internal && applications[appId].internalOnly)) && !isSysAdmin">
                                            <mat-option [value]="null">{{ 'common.none' | translate }}</mat-option>
                                            <mat-optgroup label="{{ 'common.external' | translate }} - Dealers / Distributors">
                                                <mat-option *ngFor="let role of _filter(applications[appId].roles, 'external')" [value]="role.roleId">{{ role.name }}</mat-option>
                                            </mat-optgroup>
                                            <mat-optgroup *ngIf="user.data.internal" label="{{ 'common.internal' | translate }} - Kemppi Oy / Subsidiaries">
                                                <mat-option *ngFor="let role of _filter(applications[appId].roles, 'internal')" [value]="role.roleId" [disabled]="!isKoyAdmin">{{ role.name }}</mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                        <!-- <mat-hint *ngIf="isSysAdmin && user.data.roles[appId]">{{ user.data.roles[appId] }}</mat-hint> -->
                                    </mat-form-field>
                                    <div fxFlex="200px" class="role-description">
                                        <div *ngIf="!user.data.internal && applications[appId].internalOnly">INTERNAL</div>
                                        {{ _find(applications[appId].roles, {roleId: user.data.roles[appId]})?.description }}
                                        <div *ngIf="appId === 'iam' && user.data.roles['iam'] === null || user.data.roles['iam'] === ''" class="warn">REQUIRED</div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- ATTRIBUTES -->
                            <ng-container *ngIf="applications[appId] && applications[appId].attributes && !applications[appId].publicAccess && user.data.roles[appId]">
                                <div *ngFor="let attribute of applications[appId].attributes" fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px" class="app-attribute">
                                    <mat-checkbox *ngIf="attribute.attributeType === 'checkbox'" fxFlex="150px"
                                        [checked]="user.data.appAttributes && user.data.appAttributes[appId] && user.data.appAttributes[appId][attribute.key]"
                                        (change)="setAppAttributeCheckbox(appId, attribute.key, $event)"
                                        [required]="attribute.required"
                                        [disabled]="attribute.disabled && !isSysAdmin"
                                    >{{ attribute.name }}</mat-checkbox>
                                    <mat-form-field *ngIf="attribute.attributeType === 'select' || attribute.attributeType === 'multiselect'" fxFlex="150px">
                                        <mat-label>{{ attribute.name }}</mat-label>
                                        <mat-select (selectionChange)="setAppAttributeSelect(appId, attribute.key, $event)"
                                            [multiple]="attribute.attributeType === 'multiselect'"
                                            [required]="attribute.required"
                                            [disabled]="attribute.disabled && !isSysAdmin">
                                            <mat-option *ngFor="let selectValue of attribute.selectValues" [value]="selectValue">{{ selectValue }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div fxFlex="200px" class="role-description">
                                        <div *ngIf="attribute.description && user.data.appAttributes && user.data.appAttributes[appId] && user.data.appAttributes[appId][attribute.key]">
                                            {{ attribute.description }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isCustomerSuperUser && superUser && superUserAllowedApplications">
                        <!-- PARTNER SUPER USER EDIT -->
                        <h2 class="mat-subhead">{{ 'common.applications' | translate }}</h2>
                        <ng-container *ngFor="let appId of applicationIds">
                            <ng-container *ngIf="applications[appId] && applications[appId].roles && authUser.applications.indexOf(appId) >= 0 && applications[appId].allowSuperUserAddAccess">
                                <div fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                    <h4 fxFlex="150px" [ngClass]="{'disabled-app': applications[appId].disabled}">{{ applications[appId].name }}</h4>
                                    <!-- Allow application -->
                                    <mat-checkbox [(ngModel)]="superUserAllowedApplications[appId]" (change)="updateRolesBySuperUser()"></mat-checkbox>
                                    <!-- Forced role -->
                                    <div *ngIf="!applications[appId].allowSuperUserRoleChange">
                                        {{ _find(applications[appId].roles, { roleId: user.data.roles[appId] })?.name }}
                                    </div>
                                    <!-- Allow change role -->
                                    <mat-form-field *ngIf="applications[appId].allowSuperUserRoleChange" fxFlex="150px">
                                        <mat-select [(value)]="user.data.roles[appId]" [disabled]="!superUserAllowedApplications[appId]">
                                            <mat-option [value]="null" (onSelectionChange)="superUserAllowedApplications[appId] = false">{{ 'common.none' | translate }}</mat-option>
                                            <mat-option *ngFor="let role of _filter(applications[appId].roles, 'external')" [value]="role.roleId">{{ role.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="applications[appId].allowSuperUserRoleChange" fxFlex="200px" class="role-description">{{ _find(applications[appId].roles, {roleId: user.data.roles[appId]})?.description }}</div>
                                </div>
                                <!-- ATTRIBUTES -->
                                <ng-container *ngIf="applications[appId] && applications[appId].attributes && !applications[appId].publicAccess && user.data.roles[appId]">
                                    <ng-container *ngFor="let attribute of applications[appId].attributes">
                                        <div *ngIf="attribute.allowSuperUserAddAccess" fxLayout="wrap row" fxLayoutAlign="start center" fxLayoutGap="20px" class="app-attribute" style="padding-left:45px;">
                                            <mat-checkbox *ngIf="attribute.attributeType === 'checkbox'" fxFlex="150px"
                                                [checked]="user.data.appAttributes && user.data.appAttributes[appId] && user.data.appAttributes[appId][attribute.key]"
                                                (change)="setAppAttributeCheckbox(appId, attribute.key, $event)"
                                                [required]="attribute.required"
                                                [disabled]="attribute.disabled && !isSysAdmin"
                                            >{{ attribute.name }}</mat-checkbox>
                                            <mat-form-field *ngIf="attribute.attributeType === 'select' || attribute.attributeType === 'multiselect'" fxFlex="150px">
                                                <mat-label>{{ attribute.name }}</mat-label>
                                                <mat-select (selectionChange)="setAppAttributeSelect(appId, attribute.key, $event)"
                                                    [multiple]="attribute.attributeType === 'multiselect'"
                                                    [required]="attribute.required"
                                                    [disabled]="attribute.disabled && !isSysAdmin">
                                                    <mat-option *ngFor="let selectValue of attribute.selectValues" [value]="selectValue">{{ selectValue }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div fxFlex="200px" class="role-description">
                                                <div *ngIf="attribute.description && user.data.appAttributes && user.data.appAttributes[appId] && user.data.appAttributes[appId][attribute.key]">
                                                    {{ attribute.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- LOGIN HISTORY -->
                <div *ngIf="isKoyAdmin || isSubAdmin" fxLayout="column" fxFlex="580px">
                    <h2 class="mat-subhead">{{ 'maintain_users.login_history' | translate }}</h2>
                    <ng-container *ngIf="userLogs && userLogs.logs">
                        <ng-container *ngFor="let log of userLogs.logs">
                            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px" class="log-history" [ngClass]="{'log-success': (log.type === 's'), 'log-failed': (log.type.startsWith('f'))}">
                                <div fxFlex="120px">{{ log.date | date:'short':undefined:localeService.locale }}</div>
                                <div fxFlex="160px">{{ Auth0LogEventTypeCodes[log.type]?.event }}</div>
                                <div fxFlex="120px">{{ log.client_name }}</div>
                                <!--
                                <div fxFlex="350px" class="log-description">{{ Auth0LogEventTypeCodes[log.type]?.description }}</div>
                                -->
                                <div *ngIf="isSysAdmin" fxFlex="120px">{{ log.location_info?.city_name }}, {{ log.location_info?.country_name }}</div>
                            </div>
                            <div *ngIf="log.type && log.type.startsWith('f')" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px" class="log-history log-description">
                                <div fxFlex="120px"></div>
                                <div fxFlex>{{ Auth0LogEventTypeCodes[log.type]?.description }}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <!-- META -->
            <ng-container *ngIf="user.id && (isSubAdmin || isKoyAdmin)">
                <div class="full" fxLayout="row">
                    <fieldset fxFlex class="limited-access">
                        <legend>META</legend>
                        <div fxLayout="row wrap" fxLayoutAlign="start start">
                            <div *ngIf="user.schema && isSysAdmin" class="user-stamp">
                                <b>Schema</b><br>
                                {{user.schema?.type}}<br>
                                {{user.schema?.version}}
                            </div>
                            <ng-container *ngIf="user.version">
                                <div *ngIf="user.version.created" class="user-stamp">
                                    <b>{{ 'common.created' | translate }}</b><br>
                                    {{user.version.created.timestamp | date:'short':undefined:localeService.locale}}<br>
                                    {{user.version.created.user?.name}}
                                </div>
                                <div *ngIf="user.version.modified" class="user-stamp">
                                    <b>{{ 'common.modified' | translate }}</b><br>
                                    {{user.version.modified.timestamp | date:'short':undefined:localeService.locale}}<br>
                                    {{user.version.modified.user?.name}}
                                </div>
                            </ng-container>
                        </div>
                    </fieldset>
                </div>
            </ng-container>
        
        </div>
    </div>

    <div fxFlex>
        <div *ngIf="usersList" class="list-container users-list" fxFlex="350px" fxLayout="column" fxLayoutAlign="start start">
            <h2 class="mat-subhead">{{ user.data.partnerName }} - {{ user.partnerId }} / {{ user.company }}</h2>
            <ng-container *ngFor="let usr of usersList">
                <div fxLayout="row" class="list-row list-row-tighter" [routerLink]="['/management/', usr.id]" routerLinkActive="active" [ngClass]="{'NEW': usr.data.approval === 'UNDETERMINATED', 'NOT-ACTIVE': !usr.active || usr.data.approval === 'DECLINED'}">
                    <div fxFlex="200px" fxLayoutAlign="start center">{{ usr.data.name }}</div>
                    <small *ngIf="usr.data.roles && usr.data.roles.iam && usr.data.roles.iam === 'cus_super'" fxLayoutAlign="start center">* Customer Super User</small>
                    <small *ngIf="usr.data.roles && usr.data.roles.iam && usr.data.roles.iam === 'sub_admin'" fxLayoutAlign="start center">* Subsidiary Admin</small>
                    <small *ngIf="usr.data.roles && usr.data.roles.iam && usr.data.roles.iam === 'koy_admin'" fxLayoutAlign="start center">* Kemppi Admin</small>
                    <small *ngIf="usr.data.roles && usr.data.roles.iam && usr.data.roles.iam === 'sys_admin'" fxLayoutAlign="start center">* System Admin</small>
                    <div fxFlex></div>
                </div>
            </ng-container>
        </div>
    </div>

</div>

<!-- ACTIONBAR -->
<ks-actionbar
        [hasCancel]="true"
        [hasSave]="true"
        [canSave]="!state.loading && user && user.data && user.data.roles && user.data.roles['iam'] !== null && user.data.roles['iam'] !== ''"
        (save)="save()"
        (cancel)="cancel()"
>
    <ng-container *ngIf="isSysAdmin">
        <button mat-button color="warn" (click)="showKoyTools = !showKoyTools">
            <mat-icon class="kemppi-symbols">s</mat-icon>
        </button>
        <ng-container *ngIf="showKoyTools">
            <div *ngIf="user && user.id && !state.loading">
                <button mat-button mat-raised-button color="warn" (click)="openDeleteConfirmDialog()" [disabled]="!user.id">
                    <mat-icon>delete</mat-icon> {{ 'common.delete' | translate }}
                </button>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="user && !state.loading && (isKoyAdmin || isSubAdmin)">
        <div *ngIf="user.id && user.data && !user.data.internal">
            <button mat-button mat-raised-button color="accent" (click)="openResetPasswordDialog()" [disabled]="!user.data.userId">
                {{ 'maintain_users.reset_password' | translate }}
            </button>
        </div>
    </ng-container>

    <div fxFlex></div>

    <div *ngIf="state.loading" fxLayout="row" fxLayoutAlign="start center">
        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
        &nbsp;{{ 'common.loading' | translate }}
    </div>                

    <div fxFlex></div>
</ks-actionbar>
