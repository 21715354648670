import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange, MatDialog, MatDialogConfig, MatSelectChange, MatSnackBar } from '@angular/material';

// Services
import { AuthService } from 'src/app/services/auth.service';
import { LocaleService } from 'src/app/services/locale.service';
import { MachineryService } from 'src/app/services/machinery.service';
import { ManagementService, UserSearchResponse } from 'src/app/services/management.service';
import { StateService } from 'src/app/services/state.service';

// Models
import { Company, Companies } from 'src/app/models/company';
import { Countries } from 'src/app/models/countries';
import { Permissions, UserProfile } from 'src/app/models/auth-user';
import { CreateUserRequestBody, IAMUser, REGISTRATION_APPROVAL } from 'src/app/models/IAMUser';
import { ApplicationIds, Applications, IApplications } from 'src/app/models/applications';

// Dialogs
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';
import { IUserLogs } from 'src/app/models/user-logs';
import { Auth0LogEventTypeCodes } from 'src/app/models/Auth0LogEventTypeCodes';

@Component({
    selector: 'app-user-edit-page',
    templateUrl: './user-edit-page.component.html',
    styleUrls: ['./user-edit-page.component.scss']
})
export class UserEditPageComponent implements OnInit {

    /**
     * User id
     * UUID or 'new' = create new
     */
    id: string;

    /**
     * Managed IAM user
     */
    user: IAMUser;

    /**
     * Partners super user
     */
    superUser: IAMUser;

    /**
     * Partners all users
     */
    usersList: Array<IAMUser>;

    superUserAllowedApplications: {
        [prop: string]: boolean;
    }

    /**
     * Applications ids Array<string>
     */
    applicationIds: Array<string>;

    /**
     * Applications objects { 'appId': {...} }
     */
    applications: IApplications;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Registration approval states
     */
    regApprovalStates = REGISTRATION_APPROVAL;

    /**
     * Profile of authenticated user
     */
    authUser: UserProfile;

    isSysAdmin: boolean;
    isKoyAdmin: boolean;
    isSubAdmin: boolean;
    isCustomerSuperUser: boolean;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    availableLanguages: Array<string>;

    countryList: Array<string>;

    state: {
        loading: boolean;
    };

    // Customer/partner text search
    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();

    /**
     * Show Kemppi Oy admin tools
     */
    showKoyTools: boolean;

    /**
     * Auth0 user logs
     */
    userLogs: IUserLogs;
    Auth0LogEventTypeCodes = Auth0LogEventTypeCodes;

    _filter = _.filter;
    _find = _.find;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private authService: AuthService,
        public localeService: LocaleService,
        private machineryService: MachineryService,
        private managementService: ManagementService,
        private stateService: StateService,
        private dialog: MatDialog
    ) {
        this.companies = Companies;
        this.applicationIds = ApplicationIds;
        this.applications = Applications;
        this.availableLanguages = ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'nb', 'nl', 'pl', 'ru', 'sv', 'zh'];
        this.countryList = Countries;
    }

    ngOnInit() {
        this.state = {
            loading: false
        };
        this.showKoyTools = false;
        this.isSysAdmin = false;
        this.isKoyAdmin = false;
        this.isSubAdmin = false;
        this.isCustomerSuperUser = false;
        setTimeout(() => {
            this.authUser = this.authService.userProfile;
            this.permissions = this.authUser.permissions;
            if (this.authUser && this.authUser.role === 'sys_admin') {
                this.isSysAdmin = true;
                this.isKoyAdmin = true;
            } else if (this.authUser && this.authUser.role === 'koy_admin') {
                this.isKoyAdmin = true;
            } else if (this.authUser && this.authUser.role === 'sub_admin') {
                this.isSubAdmin = true;
            } else if (this.authUser && this.authUser.role === 'cus_super') {
                this.isCustomerSuperUser = true;
            }

            this.activatedRoute.params.subscribe(res => {
                this.id = res.id;
                if (this.id === 'new') {
                    this.stateService.state.file = this.id;
                    this.initNewUser();
                } else {
                    this.getUser();
                }
            })
            // this.id = _.get(this.activatedRoute.snapshot.params, 'id');
            // if (this.id === 'new') {
            //     this.stateService.state.file = this.id;
            //     this.initNewUser();
            // } else {
            //     this.getUser();
            // }
        });
    }

    setLoading(loading) {
        this.state.loading = loading;
    }

    getUser() {
        this.setLoading(true);
        this.managementService.getUser(this.id).subscribe((user: IAMUser) => {
            this.user = user;
            if (!this.user.data.appAttributes) {
                this.user.data.appAttributes = {};
            }
            if (!this.user.data.partnerName || this.user.data.partnerName === '') {
                this.updatePartnerName();
            } else {
                this.searchPartner = this.user.data.partnerName;
            }
            this.stateService.state.file = this.user.data.name;
            this.setLoading(false);
            this.getUserLogs();

            this.getCustomerUsers();
        });
    }

    initNewUser() {
        this.user = {
            id: null,
            active: true,
            company: this.authUser.company,
            partnerId: null,
            data: {
                name: '',
                email: '',
                // partnerName: '',
                language: this.authUser.language,
                roles: {
                    iam: 'cus_user'
                },
                appAttributes: {}
            },
            schema: {
                type: 'USER',
                version: '0.1'
            }
        };

        if (this.isCustomerSuperUser) {
            this.user.partnerId = this.authUser.partnerId;
            this.user.data.partnerName = this.authUser.partnerName;
            this.searchPartner = this.user.data.partnerName;
            this.updatePartnerName();
            this.getCustomerUsers();
        }
    }

    getCustomerUsers() {
        // Search customer/partner users and find super user
        const searchParams = {
            company: this.user.company || this.authUser.company,
            partnerId: this.user.partnerId || this.authUser.partnerId,
            size: 1000
        };
        this.managementService.searchUsers(searchParams).subscribe((customerUsers: UserSearchResponse) => {
            this.usersList = customerUsers.items;
            const superUser = _.find(this.usersList, ['data.roles.iam', 'cus_super']);
            this.superUser = superUser;
            if (this.isCustomerSuperUser && this.superUser) {
                this.updateUserDefaultsBySuperUser();
            }
        });
    }

    updateUserDefaultsBySuperUser() {
        this.superUserAllowedApplications = {};
        if (this.superUser && this.id === 'new') {
            this.user.data.language = this.superUser.data.language;
            this.user.data.country = this.superUser.data.country;
            for (const app in this.superUser.data.roles) {
                if (Applications[app].allowSuperUserAddAccess && this.superUser.data.roles[app] !== null) {
                    this.superUserAllowedApplications[app] = true;
                }
            }
        } else if (this.superUser && this.user) {
            for (const app in this.user.data.roles) {
                if (Applications[app].allowSuperUserAddAccess && this.user.data.roles[app] !== null) {
                    this.superUserAllowedApplications[app] = true;
                }
            }
        }

        this.updateRolesBySuperUser();
    }

    updateRolesBySuperUser() {
        if (this.superUser) {
            for (const app in this.superUser.data.roles) {
                if (Applications[app].allowSuperUserAddAccess && this.superUser.data.roles[app] !== null) {
                    if (this.superUserAllowedApplications[app] === true) {
                        if (app === 'iam' && this.user.id !== this.superUser.id) {
                            this.user.data.roles[app] = 'cus_user';
                        } else {
                            this.user.data.roles[app] = this.superUser.data.roles[app];
                        }
                    } else {
                        if (this.user.data.roles[app]) {
                            this.user.data.roles[app] = null;
                        }
                    }
                }
            }
        }
    }

    getUserLogs() {
        if (this.isKoyAdmin || this.isSubAdmin) {
            this.managementService.getUserLogs(this.id).subscribe((userLogs) => {
                // console.log('userLogs', userLogs);
                this.userLogs = userLogs;
            });
        }
    }

    save() {
        this.setLoading(true);
        if (this.user.id === null) {
            if (!this.user.company) {
                this.notify('Company Missing from user', true);
                this.setLoading(false);
                return;
            }

            if (!this.user.partnerId) {
                this.setLoading(false);
                this.notify('Partner id Missing from user', true);
                return;
            }
            // Map user to create request format
            const createUser: CreateUserRequestBody = {
                company: this.user.company,
                partnerId: this.user.partnerId,
                partnerName: this.user.data.partnerName,
                country: this.user.data.country,
                email: this.user.data.email,
                name: this.user.data.name,
                roles: this.user.data.roles,
                language: this.user.data.language,
                internal: this.user.data.internal,
                initial: this.user.data.initial,
                appAttributes: this.user.data.appAttributes,
            };
            this.managementService.createUser(createUser).subscribe((result: string) => {
                if (result) {
                    this.id = result;
                    this.user.id = result;
                }
                this.notify('User created');
                setTimeout(() => {
                    this.getUser();
                    this.router.navigate([`/management/${this.user.id}`]);
                }, 2000);
            }, (error) => {
                console.log('error', error);
                this.notify('Failed to save user: ' + (error.error.errorMessage || error.message), true);
                this.setLoading(false);
            });
        } else {
            // console.log('user', JSON.stringify(this.user));
            this.managementService.updateUser(this.id, this.user).subscribe(() => {
                this.notify('User updated');
                setTimeout(() => {
                    this.getUser();
                }, 2000);
            }, (error) => {
                console.log('error', error);
                this.notify('Failed to save user: ' + (error.error.errorMessage || error.message), true);
                this.setLoading(false);
            });
        }
    }

    cancel() {
        this.router.navigate(['management']);
    }

    openDeleteConfirmDialog() {
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.are_you_sure_you_want_to_delete',
                content: 'confirm_dialog.delete_user',
                buttons: {
                    cancel: true,
                    ok: true
                }
            }
        };
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                this.setLoading(true);
                this.managementService.deleteUser(this.id).subscribe(() => {
                    this.notify('User deleted');
                    setTimeout(() => {
                        this.router.navigate(['management']);
                    }, 2000);
                }, (error) => {
                    console.log('error', error);
                    this.notify('Failed to delete user: ' + (error.error.errorMessage || error.message), true);
                    this.setLoading(false);
                });
            }
        });
    }

    openResetPasswordDialog() {
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.are_you_sure_you_want_to_reset_password',
                content: 'confirm_dialog.reset_user_password',
                buttons: {
                    cancel: true,
                    ok: true
                }
            }
        };
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                this.setLoading(true);
                this.managementService.resetUserPassword(this.id).subscribe(() => {
                    this.notify('Password reset email sent');
                    setTimeout(() => {
                        this.router.navigate(['management']);
                    }, 2000);
                }, (error) => {
                    console.log('error', error);
                    this.notify('Failed to reset user password: ' + (error.error.errorMessage || error.message), true);
                    this.setLoading(false);
                });
            }
        });
    }

    updatePartnerName() {
        if (!this.user.company || !this.user.partnerId) {
            return;
        }
        this.setLoading(true);
        this.machineryService.getCustomerInfo(this.user.company, this.user.partnerId).subscribe((customerInfo) => {
            // console.log('CUSTOMER INFO', customerInfo);
            this.user.data.partnerName = customerInfo.name;
            this.user.data.groupCode = customerInfo.groupCode;
            this.user.data.groupDesc = customerInfo.groupDesc;
            this.searchPartner = this.user.data.partnerName;
        }, (error) => {
            console.log('error', error);
        }, () => {
            this.setLoading(false);
        });
    }

    /**
     * Check user email, if it contains @kemppi.com check the internal checkbox
     */
    checkUserEmail() {
        this.user.data.email = this.user.data.email.replace(/ /ig, '');
        const validEmailEndings = ['@kemppi.com', '@trafimet.com']
        if ( validEmailEndings.some( emailEnd => this.user.data.email.endsWith(emailEnd)) ) {
            this.user.data.internal = true;
        } else if (this.user.data.email.indexOf('@kempower.com') >= 0) {
        } else {
            delete this.user.data.internal;
            for (const appId of this.applicationIds) {
                if (this.applications[appId].internalOnly && this.user.data.roles[appId]) {
                    delete this.user.data.roles[appId];
                }
                for (const role of this.applications[appId].roles) {
                    if (this.user.data.roles[appId] && this.user.data.roles[appId] === role.roleId && role.internal) {
                        delete this.user.data.roles[appId];
                    }
                }
            }
        }
    }

    searchCustomers() {
        if (this.searchPartner && this.searchPartner.length > 3) {
            clearTimeout(this.searchCustomerTimer);
            this.searchCustomerTimer = setTimeout(() => {
                const params = [
                    { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' }
                ];
                this.machineryService.searchCustomers(params).subscribe((results) => {
                    this.customersList = results as Array<any>;
                }, (error) => {
                    console.log('Error, searchCustomers', error);
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changeCustomer(customer) {
        if (customer && customer.value && customer.value.name && customer.value.custId && customer.value.company) {
            // console.log('changeCustomer', customer.value);
            this.searchPartner = customer.value.name;
            this.user.partnerId = customer.value.custId;
            this.user.company = customer.value.company;
            this.user.data.partnerName = customer.value.name;
            this.updatePartnerName();
        }
    }

    /**
     * Notify with snackbar, TODO: Create notify service
     */
    notify(message, error = false, duration = 3000) {
        const panelClass = ['war-snackbar'];
        if (error) {
            panelClass.push('war-snackbar-error-message');
        }
        this.snackBar.open(message, 'OK', {
            duration,
            panelClass
        });
    }

    setAppRole(appId, key) {
        // TODO
    }

    setAppAttributeCheckbox(appId, key, event: MatCheckboxChange) {
        // console.log(appId, key, event);
        if (event.checked) {
            _.set(this.user.data.appAttributes, [appId, key], true);
        } else {
            this.clearAppAttribute(appId, key);
        }
    }

    setAppAttributeSelect(appId, key, event: MatSelectChange) {
        console.log(appId, key, event);
        if (event.value
            && (
                (!Array.isArray(event.value) && event.value !== '')
                || (Array.isArray(event.value) && event.value.length > 0)
            )
        ) {
            _.set(this.user.data.appAttributes, [appId, key], event.value);
        } else {
            this.clearAppAttribute(appId, key);
        }
    }

    clearAppAttribute(appId, key) {
        if (_.get(this.user.data.appAttributes, [appId, key])) {
            // Remove key if not true
            delete this.user.data.appAttributes[appId][key];
            // Clear empty object
            if (_.isEmpty(this.user.data.appAttributes[appId])) {
                delete this.user.data.appAttributes[appId];
            }
        }
    }

}
