
export type IApplications = Record<IApplicationIds, IAMApplication>;

export type IApplicationIds = 'configurator' | 'eordering' | 'iam' | 'support';

export interface IAMApplication {
    /**
     * Application id
     */
    id: string;

    /**
     * UI shown name for the application
     */
    name: string;

    /**
     * Disabled, cannot be changed in user-editing page
     * Only system admin can change
     */
    disabled?: boolean;

    /**
     * Image filename
     * /assets/images/services/[filename]
     */
    image: string;

    /**
     * Image style, rounded (with border radius) or square
     */
     imageStyle?: 'rouded' | 'square';

    /**
     * Environments of application
     * prod is required!
     */
    environments: {
        dev?: {
            startUrl: string;
            startUrls?: object;
        }
        stage?: {
            startUrl: string;
            startUrls?: object;
        }
        prod: {
            /**
             * Start url of applications
             */
            startUrl: string;
            startUrls?: object;
        }
    };

    /**
     * Allow super user add access
     * If set true, customer super user can add access for the application
     */
    allowSuperUserAddAccess?: boolean;

    /**
     * Allow super user role change
     * If set true, customer super user can change external roles
     */
    allowSuperUserRoleChange?: boolean;

    /**
     * Disabled for non internal users
     */
    internalOnly?: boolean;

    /**
     * Public access to applications, only link to url in services listing
     * Role or permission are not required and are not maintained in MyTrafimet
     */
    publicAccess?: boolean;

    /**
     * List of roles
     */
    roles: Array<IAMApplicationRole>;

    /**
     * Application specific attributes.
     * Shown and editable in IAM UI - user edit page.
     * 
     * Passed from IAM to end application during login.
     * 
     * FROM: IAM user.data.appAttributes[applicationId] =>
     * TO: APP user.custom_attributes.attributes
     */
    attributes?: Array<IAMApplicationAttribute>;
}

export interface IAMApplicationRole {
    /**
     * Role id
     * Unique within the application
     * Changes to these might need changes to client applications role and permission handlings
     */
    roleId: string;

    /**
     * UI shown name for the role
     */
    name: string;

    /**
     * External role
     * - Lower level role
     * - Assign for Dealer / Distributor / Customer users
     * - Can be assigned by IAM SUB admins
     */
    external?: boolean;

    /**
     * Internal role
     * - Higher level role
     * - Assign only for Kemppi Oy / Subsidiary users
     * - Can only be assigned by IAM KOY Admins
     */
    internal?: boolean;

    /**
     * Role description
     * Shown with role selection
     */
    description?: string;
}

export interface IAMApplicationAttribute {
    key: string;
    name: string;
    attributeType: 'text' | 'checkbox' | 'select' | 'multiselect';
    selectValues?: Array<string>;
    required?: boolean;
    disabled?: boolean;
    description?: string;
    allowSuperUserAddAccess?: boolean;
}

/**
 * Application ids
 * Also determines the order of applications shown in services listing
 */
export const ApplicationIds: IApplicationIds[] = [
    'configurator',
    'eordering', // Will be replaced to eordering.kemppi and eordering.trafimet in portal service listing
    'iam',
    'support'
];

/**
 * Applications
 * Manage application roles in this!
 */
export const Applications: IApplications = {
    // CONFIGURATOR
    'configurator': {
        id: 'configurator',
        name: 'Configurator',
        image: 'trafimet.png',
        environments: {
            dev: { startUrl: 'https://configurator.dev.cloud.trafimet.com' },
            stage: { startUrl: 'https://configurator.stage.cloud.trafimet.com' },
            prod: { startUrl: 'https://configurator.trafimet.com' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'ext_user',   name: 'External User',  external: true },
            // Internal
            { roleId: 'int_user',   name: 'Internal User',  internal: true },
            { roleId: 'sys_admin',  name: 'Admin',          internal: true, description: 'System administrator' }
        ]
    },

    // E-ORDERING
    'eordering': {
        id: 'eordering',
        name: 'e-Ordering',
        image: 'trafimet.png',
        environments: {
            // See subsystems eordering.kemppi and eordering.trafimet
            // eOrdering start urls changes by users company
            dev: {
                startUrl: 'https://kemppi-it-trafimet-dev.parttrap.com/',
                startUrls: { '800': 'https://kemppi-it-trafimet-dev.parttrap.com/user/externallogin/Trafimet' }
            },
            stage: {
                startUrl: 'https://kemppi-it-trafimet-staging.parttrap.com/',
                startUrls: { '800': 'https://kemppi-it-trafimet-staging.parttrap.com/user/externallogin/Trafimet-staging' }
            },
            prod: {
                startUrl: 'https://eordering-it.trafimet.com/',
                startUrls: { '800': 'https://eordering-it.trafimet.com/user/externallogin/Trafimet' }
            }
        },
        allowSuperUserAddAccess: true,
        allowSuperUserRoleChange: true,
        roles: [
            // External
            { roleId: '1013', name: 'Trafimet - Standard full', external: true, description: 'Can view, put in cart and place order. Can view order and invoice history.' },
            // Internal
            { roleId: '1012', name: 'Trafimet - Internal tester', internal: true, description: 'Same as standard customer, but able to change customer ID' }
        ],
        // TODO: WHEN eordering Trafimet goes live, remove Trafimet disabled
        attributes: [
            // See subsystems eordering.kemppi and eordering.trafimet
            // { key: 'kemppi', name: 'Kemppi', attributeType: 'checkbox', description: 'Can access Kemppi products' },
            // { key: 'trafimet', name: 'Trafimet', attributeType: 'checkbox', description: 'Can access Trafimet products' }
        ]
    },

    // MYTRAFIMET IAM
    'iam': {
        id: 'iam',
        name: 'MyTrafimet portal',
        image: 'iam.png',
        environments: {
            dev: { startUrl: 'https://mytrafimet.dev.cloud.trafimet.com' },
            stage: { startUrl: 'https://mytrafimet.stage.cloud.trafimet.com' },
            prod: { startUrl: 'https://my.trafimet.com' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'cus_user', name: 'User', external: true, description: 'Can see portal, list of allowed application based on user roles' },
            { roleId: 'cus_super', name: 'Customer Super User', external: true, description: 'Can manage own users' },
            // Internal
            { roleId: 'sys_admin', name: 'System Admin', internal: true, description: 'System administrator' },
            { roleId: 'koy_admin', name: 'Kemppi Oy Admin', internal: true, description: 'Can manage users globally' },
            { roleId: 'sub_admin', name: 'Subsidiary Admin', internal: true, description: 'Can manage users in same company' }
        ]
    },
    // TRAFIMET SUPPORT
    'support': {
        id: 'support',
        name: 'Trafimet support (JIRA)',
        image: 'Jira Service Desk-icon-blue.svg',
        publicAccess: true,
        environments: {
            // Kemppi support start urls changes by users company
            dev: {
                startUrl: 'https://kemppi.atlassian.net/servicedesk/customer/portal/56',
            },
            stage: {
                startUrl: 'https://kemppi.atlassian.net/servicedesk/customer/portal/56',
            },
            prod: {
                startUrl: 'https://kemppi.atlassian.net/servicedesk/customer/portal/56',
            }
        },
        roles: []
    }
};
