<div class="main" fxLayout="column">
    <div class="page-container full" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <h1>My Trafimet</h1>

        <div fxLayout="column" fxLayoutAlign="center center">
            <!--
            <button mat-button color="accent" routerLinkActive="active" routerLink="/mobile-devices">Link your Kemppi Connect tool to service</button>
            -->
            <!--
            <a (click)="getToken()">
                <h3>Link your Kemppi Connect tool to service</h3>
            </a>
            <ng-container *ngIf="activationToken">
                <div fxLayoutAlign="center center">
                    Get it from google play.
                    <img src="assets/images/google-play.png" style="width: 130px; height: 50px">
                </div>
                <div>Open Kemppi Connect tool and read following QR-code with the application</div>
                <div fxFlex="200px">
                    <ks-barcode [(ngModel)]="activationToken" [qrCodeSize]="1.4" type="2D"></ks-barcode>
                </div>
            </ng-container>
            -->
        </div>

        <div fxLayout="column">
            <div *ngIf="allowedModules" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                <button mat-button *ngIf="allowedModules['portal']" routerLinkActive="active" routerLink="/portal" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">ą</mat-icon>
                        </div>
                        <div>{{ 'menu.portal' | translate }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules['management']" routerLinkActive="active" routerLink="/management" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">ź</mat-icon>
                        </div>
                        <div>{{ 'menu.management' | translate }}</div>
                    </div>
                </button>
            </div>

            <div *ngIf="!allowedModules || (!allowedModules['portal'] && !allowedModules['management'])">
                {{ 'landing.no_permission_to_any_module' | translate }}
            </div>
        </div>

    </div>
    <div class="footer full" fxLayout="column" fxFlex>
        <div fxLayout="row">
            <div fxFlex></div>
            <div fxFlex="600px" class="footer-text">
                <h2>My Trafimet</h2>
                <p>{{ 'landing.mytrafimet_description' | translate }}</p>
            </div>
            <div fxFlex></div>
            <div fxFlex="200px" class="footer-text">
            </div>
            <div fxFlex></div>
        </div>
        <div fxLayout="row" class="footer-text" fxLayoutAlign="center center">Copyright &copy; 2020 Kemppi Oy.</div>
    </div>

</div>
